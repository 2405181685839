import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../util/reactIntl';

import { Form, LocationAutocompleteInput } from '../../../components';

import css from './LocationFilter.module.css';

const identity = v => v;

const LocationSearchField = props => {
    const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange } = props;
    return (
        <Field
            name="location"
            format={identity}
            render={({ input, meta }) => {
                const { onChange, ...restInput } = input;

                // Merge the standard onChange function with custom behaviur. A better solution would
                // be to use the FormSpy component from Final Form and pass this.onChange to the
                // onChange prop but that breaks due to insufficient subscription handling.
                // See: https://github.com/final-form/react-final-form/issues/159
                const searchOnChange = value => {
                    onChange(value);
                    onLocationChange(value);
                };

                return (
                    <LocationAutocompleteInput
                        className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                        predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
                        predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
                        placeholder={intl.formatMessage({ id: 'LocationForm.placeholder' })}
                        closeOnBlur={!isMobile}
                        inputRef={inputRef}
                        input={{ ...restInput, onChange: searchOnChange }}
                        meta={meta}
                    />
                );
            }}
        />
    );
};

class LocationFormComponent extends Component {
    constructor(props) {
        super(props);
        // onChange is used for location search
        this.onChange = this.onChange.bind(this);
        this.searchInput = null;
        this.setSearchInputRef = element => {
            this.setSearchInput = element;
        };
    }

    onChange(location) {
        this.props.updateFilter(location)
    }

    render() {
        const { appConfig, showIcon, isTopBar,...restOfProps } = this.props;
        return (
            <FinalForm
                {...restOfProps}
                onSubmit={() => { }}
                render={formRenderProps => {
                    const {
                        rootClassName,
                        className,
                        desktopInputRoot,
                        intl,
                        isMobile,
                    } = formRenderProps;
                    const classes = classNames(rootClassName, className);
                    const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

                    // Location search: allow form submit only when the place has changed
                    const preventFormSubmit = e => e.preventDefault();
                    const submitFormFn = preventFormSubmit;

                    return (
                        <Form className={classes} onSubmit={submitFormFn} enforcePagePreloadFor="SearchPage">
                            <LocationSearchField
                                desktopInputRootClass={desktopInputRootClass}
                                intl={intl}
                                isMobile={isMobile}
                                inputRef={this.setSearchInputRef}
                                onLocationChange={this.onChange}
                            />
                        </Form>
                    );
                }}
            />
        );
    }
}

LocationFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    desktopInputRoot: null,
    isMobile: false,
    isTopBar: false,
};

LocationFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    desktopInputRoot: string,
    onSubmit: func.isRequired,
    isMobile: bool,
    appConfig: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const LocationForm = injectIntl(LocationFormComponent);

export default LocationForm;
