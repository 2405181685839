import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';


import { injectIntl, intlShape } from '../../../util/reactIntl';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './LocationFilter.module.css';
import { Field } from 'react-final-form';
import { LocationAutocompleteInput } from '../../../components';
import LocationForm from './LocationForm';
import { parse, stringify } from '../../../util/urlHelpers';

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;
// Short search queries (e.g. 2 letters) have a longer timeout before search is made
const TIMEOUT_FOR_SHORT_QUERIES = 2000;

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
      ? queryParamNames
      : 'keywords';
};

const LocationFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    initialValues,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    intl,
    showAsPopup,
    location,
    ...rest
  } = props;

  const [filter, setFilter] = useState(null)
  const [filterContent, setFilterContent] = useState(null)

  const positionStyleForContent = () => {
    if (filter && filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - filter.getBoundingClientRect().right;
      const labelWidth = filter.offsetWidth;
      const contentWidth = filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  const classes = classNames(rootClassName || css.root, className);

  const urlParam = getKeywordQueryParam(queryParamNames);
  const hasInitialValues =
    !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;
  const labelForPopup = hasInitialValues
    ? intl.formatMessage(
      { id: 'LocationFilter.labelSelected' },
      { labelText: initialValues[urlParam] }
    )
    : label;

  const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;
  const labelForPlain = <span className={labelClass}>{label}</span>;

  const filterText = label === intl.formatMessage({ id: 'FilterComponent.keywordsLabel' }) ? intl.formatMessage({ id: 'LocationFilter.filterText' }) : "";


  const contentStyle = positionStyleForContent();

  // pass the initial values with the name key so that
  // they can be passed to the correct field
  const namedInitialValues = { [name]: initialValues[urlParam] };

  const handleSubmit = values => {
    const { search, selectedPlace } = values;
    if (selectedPlace) {
      const { bounds } = selectedPlace;
      onSubmit({
        address: search,
        bounds,
      });
    }
  };

  const handleClear = () => {
    onSubmit({
      address: '',
      bounds: '',
    });
  };

  const { address, origin, bounds } = parse(typeof window !== 'undefined' && window.location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  return <>
    {showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <LocationForm
          updateFilter={handleSubmit}
          initialValues={{
            location: {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          }}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onClear={handleClear}
        initialValues={namedInitialValues}
        {...rest}
      >
        <fieldset className={css.fieldPlain}>
          <label className={css.fieldPlainLabel}>{filterText}</label>
          <LocationForm
            updateFilter={handleSubmit}
            initialValues={{
              location: {
                search: address,
                selectedPlace: { address, origin, bounds },
              }
            }}
          />
        </fieldset>
      </FilterPlain>
    )}
  </>
}

LocationFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

LocationFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default LocationFilter;
